<template>
  <div>

    <!--    Header Title And Navigation-->
    <div v-if="displayHide('sm')"
         class="d-flex justify-space-between mb-6">
      <div class="d-flex align-center">
        <v-btn :to="businessDashLink('courses')" outlined  icon class="mr-3" :color="wsDARKLIGHT">
          <v-icon  >mdi-chevron-left</v-icon>
        </v-btn>
        <div>
          <h3 style="line-height: 1.2"> {{  $store.state.courses.course.translated_name  }} </h3>
          <h5 class="font-weight-medium">{{ $t('StudySchedule')}}</h5>
        </div>

      </div>
    </div>

    <v-sheet class="wsRounded pa-4 mt-3">
      <!-- Headinng and Search Field-->
      <div class="d-flex justify-space-between align-center mt-3 pl-3">
        <h3>{{ $t('StudySchedule') }}</h3>
      </div>

      <!-- Items Table-->
      <v-data-table :items="items"
                    :headers="headers"
                    class="mt-6" >

        <!-- Items templates-->
        <!-- Name-->
        <template v-slot:item.name="{ item }">
          <h5>{{ item.name }} </h5>
        </template>

        <template v-slot:item.education_start="{ item }">
          <v-chip class="my-1 mr-2" small dark :color="wsACCENT"  >
            <v-icon small class="mr-1" > mdi-calendar</v-icon>
            <h4 class="font-weight-medium">{{item.education_start }}</h4>
          </v-chip>
        </template>

        <template v-slot:item.education_end="{ item }">
          <v-chip class="my-1 mr-2" small dark :color="wsACCENT"  >
            <v-icon small class="mr-1" > mdi-calendar</v-icon>
            <h4 class="font-weight-medium">{{item.education_end }}</h4>
          </v-chip>
        </template>


        <!-- Action-->
        <template v-slot:item.action="{ item }">

          <v-btn :to="businessDashLink('courses/' + uuid + '/study_schedule/' + item.uuid )" class="noCaps"
                 small
                  :color="wsATTENTION" dark>
            {{ $t('EditSchedule') }}
            <v-icon  >mdi-chevron-right</v-icon>
          </v-btn>

        </template>

      </v-data-table>
    </v-sheet>
    <!-- Add / Edit Item Dialog Window -->
    <wsDialog v-model="displayDialog"
              :loading="$store.state.LOADING"
              @save="saveChanges"
              :title="$t('AddNewEnrollmentWave')"
              :show-delete="!newEntity"
              @delete="deleteItem"
              :saveText="saveText">

      <h5 class="font-weight-medium">{{ $t('Name') }}:</h5>
      <v-text-field
          outlined
          dense
          v-model="entityData.name"
          :placeholder="$t('Name')"
      />
      <v-row>
        <v-col cols="6">

            <h5 class="font-weight-medium">{{ $t('Education Start') }}:</h5>
            <ws-date-picker v-model="entityData.education_start"
                            :placeholder="$t('ChooseDate')" />
        </v-col>
        <v-col cols="6">
          <h5 class="font-weight-medium">{{ $t('Education End') }}:</h5>
          <ws-date-picker v-model="entityData.education_end"
                          :placeholder="$t('ChooseDate')" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <h5 class="font-weight-medium">{{ $t('EnrollmentStart') }}:</h5>
          <ws-date-picker v-model="entityData.enrollment_start"
                          :placeholder="$t('ChooseDate')" />
        </v-col>
        <v-col cols="6">
          <h5 class="font-weight-medium">{{ $t('EnrollmentClose') }}:</h5>
          <ws-date-picker v-model="entityData.enrollment_close"
                          :placeholder="$t('ChooseDate')" />
        </v-col>
      </v-row>

    </wsDialog>

  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "courseCalendar",
  props : ['uuid'],
  data() {
    return {
      items : [],
      entityDataDefault : {
        name : '',
        firstname : '',
        password : ''
      },
      entityData : { },
      displayDialog: false,
      newEntity : true
    }
  },
  computed : {
    saveText() {
      return this.newEntity ? this.$t('Add') : this.$t('Save')
    },
    headers(){
      return [
        { text : this.$t('Name')  , value : 'name'                       },
        { text : this.$t('EducationStart') , value : 'education_start'   },
        { text : this.$t('EducationEnd') , value : 'education_end'   },
        { text : ''               , value : 'action'      , align : 'right', sortable: false, width : 10}
      ]
    } ,
  },
  methods : {

    ...mapActions('courses',['GET_ENROLLMENT_WAVES','GET_ENROLLMENT_WAVE','ADD_ENROLLMENT_WAVE', 'DELETE_ENROLLMENT_WAVE']),

    

    flushEntity() {
      this.entityData = JSON.parse(JSON.stringify(this.entityDataDefault))
      if (this.entityType === 'institute') {
        this.entityData.institute_id = this.uuid
        this.entityData = JSON.parse(JSON.stringify(this.entityData))

      }
    },
    saveChanges() {

      this.newEntity ? this.addItem(this.ADD_ENROLLMENT_WAVE(this.entityData))
                     : this.changeItem(this.ADD_ENROLLMENT_WAVE(this.entityData))
      this.displayDialog = false
      this.flushEntity()

    },
    deleteItem() {

      this.removeItem(this.DELETE_ENROLLMENT_WAVE(this.entityData.uuid) )
      this.displayDialog = false

    },

    async addItem(item) {
      let result = await item
      if (!result) { return this.notify( this.$t('NetworkError') ) }
      this.items.push(result)
      this.notify( this.$t('NewSchedulePlanCreated') )
    },
    async changeItem(item) {
      let result = await item

      if (!result) { return this.notify( this.$t('NetworkError') ) }

      var index = this.items
          .findIndex(el => el.uuid === result.uuid )
      if (index === -1 ) { return }

      this.items[index] = result
      this.$set(this.items,index,result)
      this.notify( this.$t('ChangesSaved') )
    },
    async removeItem(deleteAction) {

      if ( !await deleteAction ) {
        return this.notify(this.$t('EntityCannotBeDeleted'))
      }

      var index = this.items
          .findIndex(el => el.uuid === this.entityData.uuid )
      if (index === -1 ) { return }
      this.items.splice(index,1)
      this.notify(this.$t('EnrollmentScheduleDeleted'))

    },

    
    displayNewItem(){
      this.flushEntity()
      this.newEntity = true
      this.entityData.course_id = this.uuid
      this.displayDialog = true

    },
    async displayEditItem(item) {
      this.displayDialog = true;
      this.newEntity = false
      this.entityData = await this.GET_ENROLLMENT_WAVE(item.uuid)
      this.entityData.course_id = this.uuid
  //    this.entityData = JSON.parse(JSON.stringify(this.entityData))
    }
  },
  async mounted() {
    this.flushEntity()
    let result = await this.GET_ENROLLMENT_WAVES(this.uuid)
    if ( !result ) { return this.notify(this.$t('Error')) }
    this.items = result !== true ? result : []
  }
}
</script>

<style scoped>

</style>